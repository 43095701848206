<svelte:options tag="mobile-menu"/>

<script>
    export let _mainmenu = mainmenu.getElementsByTagName('li');
    export let _submenu = submenu.getElementsByTagName('li');
    export let _businesstoggle = businesstoggle.getElementsByTagName('li');
    export let _search = search.getElementsByTagName('li');

    let isMobileMenuOpen = false;

    function toggleMobileMenu() {
        isMobileMenuOpen = !isMobileMenuOpen;
    }

    $: document.body.classList[isMobileMenuOpen ? 'add' : 'remove']('mobile-menu-open');

</script>

<div class="mobile-menu">
    <div class="mobile-menu__menu-button-wrapper">
        <button on:click="{toggleMobileMenu}" data-collapse-toggle="mobile-menu__menu-button" type="button"
                class="mobile-menu__menu-button" aria-controls="mobile-menu__menu"
                aria-expanded="false">
            <i class="fa-thin {isMobileMenuOpen ? 'fa-xmark' : 'fa-bars'}"></i>
            <span class="mobile-menu__menu-button__span sr-only">{isMobileMenuOpen ? 'Lukk hovedmeny' : 'Åpne hovedmeny'}</span>
        </button>
    </div>
    {#if isMobileMenuOpen}
        <nav aria-label="Mobilmeny" class="mobile-menu__menu" id="mobile-menu__menu">
            <ul class="component__link-list component__link-list--strokes">
                {#each _businesstoggle as menuitem}
                    <li class="component__link-list__link">{@html menuitem.innerHTML}</li>
                {/each}
                {#each _mainmenu as menuitem}
                    <li class="component__link-list__link">{@html menuitem.innerHTML}</li>
                {/each}
            </ul>
            <ul class="mobile-menu__submenu">
                {#each _submenu as menuitem}
                    <li class="menu-item">{@html menuitem.innerHTML}</li>
                {/each}
                {#each _search as menuitem}
                    <li class="menu-item">{@html menuitem.innerHTML}</li>
                {/each}
            </ul>
        </nav>
    {/if}
</div>

<style>
    @import '/static/website/bundle.css';
    @import "https://kit.fontawesome.com/62abe87950.css";
</style>
